<template>
  <div class="user-withdraw">
    <div class="title">Withdraw</div>
    <b-form @submit.prevent="onWithdraw()">
      <div class="form-group" v-if="false">
        <label for=">FromAccount">From Account</label>
        <b-form-input
          v-model.trim="withdraw.subAccountID"
          required
          type="text"
          id="FromAccount"
          readonly
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="CoinWithdraw">Coin Withdraw</label>
        <b-form-select
          v-model.trim="withdraw.coin"
          required
          id="CoinWithdraw"
          :options="WithdrawList"
          value-field="id"
          text-field="Name"
        ></b-form-select>
      </div>
      <div class="form-group">
        <label for="ToAccount">To Address</label>
        <b-form-input
          v-model.trim="withdraw.address"
          type="text"
          id="ToAccount"
          placeholder="Enter Receive Address"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="Balance">Balance</label>
        <b-form-input
          :value="TruncateToDecimals(balance.MainBalance, 4)"
          id="Balance"
          readonly
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="Min">Min</label>
        <b-form-input v-model.trim="getInfo.MinWithdraw" readonly id="Min"></b-form-input>
      </div>
      <div class="form-group">
        <label for="Min">Fee ( $ )</label>
        <b-form-input v-model.trim="getInfo.FeeWithdraw" readonly id="Min"></b-form-input>
      </div>
      <div class="form-group">
        <label for="Amount">Amount</label>
        <b-form-input
          v-model.trim="withdraw.amount"
          required
          type="number"
          id="Amount"
          placeholder="Enter your amount"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="authCode">Two Factor Authentication</label>
        <b-form-input
          v-model.trim="withdraw.authCode"
          required
          type="text"
          id="authCode"
          placeholder="Enter your auth Code"
        ></b-form-input>
      </div>
      <div class="form-group mt-2 d-flex justify-content-center align-items-center mb-0">
        <b-button variant="danger" class="mx-2" @click="closePopup()"> Cancel </b-button>
        <b-button variant="success" class="mx-2" type="submit">Withdraw</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filter from 'lodash/filter';

const WAValidator = require('multicoin-address-validator');

export default {
  props: {
    user: {
      type: String,
      default: null,
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      withdraw: {
        coin: 16,
        // subAccountID: '',
        address: '',
        amount: '',
        authCode: '',
        token: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
      UserInfo: 'user/UserInfo',
    }),
    WithdrawList() {
      return filter(this.Coin.symbol, (elm) => elm.Withdraw === 1);
    },
    getInfo() {
      return filter(this.Coin.symbol, (elm) => elm.id === this.withdraw.coin)[0];
    },
    listAccount() {
      return [{ value: 'LQYIX338485', text: 'LQYIX338485 ' }];
    },
  },
  watch: {
    user: {
      handler() {
        this.withdraw.subAccountID = this.user;
      },
    },
    WithdrawList: {
      handler() {
        this.withdraw.coin = this.WithdrawList[0].id;
      },
    },
  },
  mounted() {
    if (this.user) {
      this.withdraw.subAccountID = this.user;
    }
    if (this.WithdrawList && this.WithdrawList.length > 0) {
      this.withdraw.coin = this.WithdrawList[0].id;
    }
  },
  methods: {
    async onWithdraw() {
      const valid = WAValidator.validate(this.withdraw.address, this.getInfo.Network);

      if (valid) {
        // console.log('This is a valid address');
      } else {
        this.$toastr.e('Your Address is not valid.', 'Withdraw Failed');
        return;
      }

      if (this.withdraw.amount < this.Coin.symbol[0].MinWithdraw) {
        this.$toastr.e(
          `Min withdraw is ${this.Coin.symbol[0].MinWithdraw}$. Please enter a larger amount of balance you want to withdraw.`,
          'Withdraw Failed',
        );
        return;
      }
      const token = await this.genarateCaptChaV3('wallet');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.withdraw.token = token;
      this.$store.dispatch('wallet/req_postWithdraw', this.withdraw);
    },
    closePopup() {
      this.$emit('closePopup', true);
    },
    updateAddress() {
      this.$router.push({ name: 'Profile' });
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'wallet/WITHDRAW_SUCCESS':
          this.$emit('closePopup', true);
          this.withdraw = {
            address: '',
            amount: '',
            authCode: '',
            token: null,
          };
          this.outLoad();
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.user-withdraw {
  min-width: 450px;
  margin: auto;
  width: 100%;
  padding: 1em;
  background-color: #0c83e9;
  .title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 22px);
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
    margin-bottom: 15px;
  }
  .form-group {
    label {
      font-weight: 600;
      color: #fff;
      font-size: clamp(14px, 2vw, 16px);
      margin-bottom: 0px;
    }
    select,
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #6c757d;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: none;
        border-bottom: 2px solid #097501;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
  }
}
</style>
