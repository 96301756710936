<template>
  <div class="box-element">
    <b-row class="top-box" ref="topBox">
      <div class="box-body">
        <div class="box-title">
          <h4>{{ title }}</h4>
        </div>
        <div class="box-value">
          <h3>{{ value }}</h3>
          <span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
        </div>
        <div class="action" v-if="action">
          <b-button
            v-if="action == 'deposit'"
            variant="light"
            @click="showDeposit"
            class="btn-animation-info"
            >Deposit</b-button
          >
          <b-button
            v-if="action == 'withdraw'"
            variant="light"
            @click="showWithdraw"
            class="btn-animation-info"
            >Withdraw</b-button
          >
          <b-button
            v-if="action == 'transfer'"
            variant="light"
            @click="showTransfer"
            class="btn-animation-info"
            >Transfer</b-button
          >
        </div>
      </div>
    </b-row>
    <b-modal
      :id="`deposit-${title}`"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Deposit
        @closePopup="$bvModal.hide(`deposit-${title}`)"
        :user="UserInfo.id"
        :balance="balance"
      />
    </b-modal>
    <b-modal
      :id="`withdraw-${title}`"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Withdraw
        @closePopup="$bvModal.hide(`withdraw-${title}`)"
        :user="UserInfo.id"
        :balance="balance"
      />
    </b-modal>
    <b-modal
      :id="`transfer-${title}`"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Transfer
        @closePopup="$bvModal.hide(`transfer-${title}`)"
        :user="UserInfo.id"
        :balance="balance"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Deposit from '@/components/wallet/Deposit.vue';
import Withdraw from '@/components/wallet/Withdraw.vue';
import Transfer from '@/components/wallet/Transfer.vue';

export default {
  components: {
    Deposit,
    Withdraw,
    Transfer,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    member: {
      type: [String],
      default: '',
    },
    balance: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deposit: {
        coin: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
      UserInfo: 'user/UserInfo',
    }),
  },
  methods: {
    showDeposit() {
      this.$bvModal.show(`deposit-${this.title}`);
    },
    showWithdraw() {
      this.$bvModal.show(`withdraw-${this.title}`);
    },
    showTransfer() {
      this.$bvModal.show(`transfer-${this.title}`);
    },
  },
};
</script>
<style lang="scss">
.modal-body {
  padding: 0px;
}
</style>
<style lang="scss" scoped>
.box-element {
  padding: 1.25rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  position: relative;
  background-color: #0c83e9;
  min-height: 100px;
  width: 100%;
  text-align: center;
  .box-body {
    text-align: center;
    .action {
      margin-top: 20px;
      .btn-light {
        background: #fff;
        min-width: 125px;
        padding: 5px;
        border-color: #fff;
        color: #000;
      }
      .btn-animation-info {
        color: #fff;
        background-color: #062b4b;
        transition: 0.3s;
        border-color: #062b4b;
        animation: glowing 2s linear infinite;
      }
      @keyframes glowing {
        0% {
          box-shadow: 0 0px 30px #062b4b;
        }
        50% {
          box-shadow: 0 0px 0px #062b4b;
        }
        100% {
          box-shadow: 0 0px 30px #062b4b;
        }
      }
    }
  }
  .top-box {
    position: relative;
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    bottom: 0;
    background: url(~@/assets/images/bg-box.png);
    background-size: 100% 100%;
    width: 100%;
    opacity: 0.4;
    background-position: top right;
    border-radius: 15px;
  }
  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 9px 12px rgba(0, 0, 0, 0.1);
  }
  .box-bottom {
    min-height: calc(200px - 80px - 1.25rem);
  }
  .box-title {
    h4 {
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 700;
      margin-top: 0;
      color: #fff;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
  .box-value {
    h3 {
      color: #ffff00 !important;
      font-weight: 600;
      margin-bottom: 0;
      font-size: clamp(18px, 3vw, 24px);
      @media (max-width: 425px) {
        font-size: clamp(20px, 3vw, 24px);
      }
    }
    margin-top: 10px;
    justify-content: flex-start;
    align-items: center;
    span {
      color: #6c757d;
      margin-left: 5px;
      font-weight: 600;
      font-size: clamp(18px, 3vw, 24px);
      text-transform: capitalize;
      @media (max-width: 425px) {
        font-size: clamp(15px, 3vw, 19px);
      }
    }
  }
  .box-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 26px;
    z-index: 5;
    color: #fff;
    box-shadow: 0 0px 2px 5px rgba(0, 0, 0, 0.1), 0 0px 2px 5px rgba(0, 0, 0, 0.1);
    background: #009750;
    @media (max-width: 1200px) {
      width: 40px;
      height: 40px;
    }
    &.img-level {
      background: transparent;
      box-shadow: none;
    }
  }
  .box-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span.level-name {
      margin-bottom: 0px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 15px;
      font-weight: 700;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
    .img-level {
      width: 75px;
      height: 75px;
      padding: 0px;
      background: transparent;
      box-shadow: none;
      filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5));
      // border: 1px solid #34a849;
    }
  }
  .text-customer-1 {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 15px;
    font-weight: 700;
    margin-top: 0;
    color: #6c757d;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
</style>
